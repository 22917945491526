import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import SEO from "../components/SEO"

const Page404 = () => {
  return (
    <Layout>
      <SEO pathname="/404" title="Not Found" lang="en" />
      <div style={{ margin: "8rem 0rem" }}>
        <h2> 404: Page not Found</h2>
        <Link to="/">Go to home page</Link>
      </div>
    </Layout>
  )
}

export default Page404
